import React from 'react';
import { InputSelect } from '~/components/NovosInputs';
import { timezones } from '~/pages/EdicaoDeConta/data/Timezones';
import { UseFormProps } from '~/pages/EdicaoDeConta/protocols';

type TimezoneContainerProps = {
  form: UseFormProps;
};

export const TimezoneContainer: React.FC<TimezoneContainerProps> = ({
  form,
}) => {
  const { register, formState, control, setValue } = form;
  const { errors } = formState;

  return (
    <>
      <InputSelect
        label="Fuso horário"
        placeholder="- Selecione o fuso horário -"
        name="timezone"
        register={register}
        isError={!!errors.timezone}
        control={control}
        options={timezones}
        changeSelected={(selected) => setValue('timezone', selected)}
      />
    </>
  );
};
