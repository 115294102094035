import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schema = yup
  .object({
    val_bc_icms: yup.string().when('flg_ignora_bc', {
      is: (value: any) => {
        if (value === true) return true;
        return false;
      },
      then: yup
        .string()
        .required()
        .test((val) => {
          const valueToFloat = transformAsCurrency(val || 0);
          return valueToFloat >= 0;
        }),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    val_icms: yup.string().when('flg_ignora_bc', {
      is: (value: any) => {
        if (value === true) return true;
        return false;
      },
      then: yup
        .string()
        .required()
        .test((val) => {
          const valueToFloat = transformAsCurrency(val || 0);
          return valueToFloat >= 0;
        }),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    val_isento: yup.string().when('flg_ignora_bc', {
      is: (value: any) => {
        if (value === true) return true;
        return false;
      },
      then: yup
        .string()
        .required()
        .test((val) => {
          const valueToFloat = transformAsCurrency(val || 0);
          return valueToFloat >= 0;
        }),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    val_outros_icms: yup.string().when('flg_ignora_bc', {
      is: (value: any) => {
        return value === true;
      },
      then: yup.string().test('empty-or-zero', 'Invalid value', (val) => {
        if (val === '' || val === '0' || val === '0,00') {
          return true;
        }
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat >= 0;
      }),
      otherwise: yup.string().notRequired().nullable(true),
    }),
  })
  .required();
