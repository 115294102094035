import moment from 'moment';
import {
  formatCurrencyAsText,
  moneyFormat,
  transformAsCurrency,
} from '~/utils/functions';
import { Critica } from '../protocols';
import { createCritica } from './CreateCritica';

interface FormDataFormated {
  val_total_nf: number;
  dta_entrada: string;
}

export function financeiroValidation(
  formatedData: FormDataFormated,
  parcelasFinanceiro: any[],
): Critica[] {
  const criticas: Critica[] = [];

  const validaParcelas = () => {
    const countParcelas = parcelasFinanceiro.length;
    if (countParcelas === 0) {
      const critica = createCritica(
        2,
        'Financeiro deve ser informado.',
        `
        Clique em (Re)Calcular para assumir as condições do fornecedor ou adicione as parcelas manualmente.
        `,
      );
      criticas.push(critica);
    }
  };

  const validaTotalParcelas = () => {
    let totalParcelas = 0;
    const totalNota = transformAsCurrency(formatedData.val_total_nf || 0);
    const initialValue = 0;
    totalParcelas = parcelasFinanceiro.reduce(
      (acc, item) => acc + item.val_parcela,
      initialValue,
    );
    totalParcelas = parseFloat(totalParcelas.toFixed(2));
    if (Number(totalParcelas) !== Number(totalNota)) {
      const critica = createCritica(
        2,
        'Total das parcelas difere do total da NF.',
        `
        Financeiro Informado: ${moneyFormat(String(totalParcelas), 2)} \n
        Financeiro Calculado: ${moneyFormat(String(totalNota), 2)}`,
      );
      criticas.push(critica);
    }
  };

  const validaDataVencimento = () => {
    const dtaEntrada = moment(formatedData.dta_entrada);
    parcelasFinanceiro.map((parcela) => {
      const dtaVencimento = moment(parcela.dta_vencimento);
      if (dtaEntrada.isAfter(dtaVencimento)) {
        const critica = createCritica(
          2,
          'Data de Vencimento deve ser maior que a Data de Entrada da NF',
          `
          Data de Entrada: ${moment(dtaEntrada).format('DD/MM/YYYY')} \n
          Valor: ${formatCurrencyAsText(parcela.val_parcela)} \n
          Data de vencimento: ${moment(dtaVencimento).format('DD/MM/YYYY')}
          `,
        );
        criticas.push(critica);
      }
      return parcela;
    });
  };

  validaParcelas();
  validaTotalParcelas();
  validaDataVencimento();

  return criticas;
}
