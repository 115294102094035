import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  #perfil {
    ul {
      z-index: 30;
    }
  }
`;
