import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import {
  InputMaskCelular,
  InputMaskCpf,
  InputPassword,
  InputText,
} from '~/components/NovosInputs';
import { TimezoneContainer } from '../Containers/TimezoneContainer';
import useAuth from '~/hooks/useAuth';
import { validaEmail } from '~/utils/functions';

type FormProps = {
  form: UseFormReturn<FieldValues, any>;
  updateForm: boolean;
};

export const Form: React.FC<FormProps> = ({ form, updateForm }) => {
  const {
    control,
    formState,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = form;

  const { errors } = formState;
  const auth = useAuth();

  useEffect(() => {
    if (auth.user) {
      const { id, usuario, email, timezone, cod_pessoa } = auth.user;

      const userEmail = updateForm ? getValues('email') : email;

      if (!validaEmail(userEmail || '')) {
        setValue('email', getValues('email'));
        setError('email', { type: 'validate' }, { shouldFocus: true });
      } else {
        setValue('email', email);
        clearErrors('email');
      }

      setValue('des_usuario', usuario);
      setValue('cod_pessoa', cod_pessoa);
      setValue('timezone', { value: timezone, label: timezone });
      setValue('cod_usuario', id);
    }
  }, [auth.user, clearErrors, getValues, setError, setValue, updateForm]);

  return (
    <>
      <Row>
        <Col sm={12} md={12} lg={12} xl={12}>
          <InputText
            label="Nome"
            placeholder="Digite o nome do usuário"
            maxLength={100}
            name="des_usuario"
            register={register}
            disabled={false}
            caseInput="upper"
            isError={!!errors.des_usuario}
            control={control}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={12} lg={6} xl={6}>
          <InputText
            label="E-mail"
            placeholder="Digite o e-mail"
            maxLength={100}
            name="email"
            register={register}
            caseInput="lower"
            disabled={false}
            isError={!!errors.email}
            control={control}
          />
        </Col>
        <Col sm={12} md={12} lg={3} xl={3}>
          <InputPassword
            placeholder="Digite a senha"
            label="Senha"
            maxLength={100}
            name="senha"
            register={register}
            disabled={false}
            isError={!!errors.senha}
            control={control}
          />
        </Col>
        <Col sm={12} md={12} lg={3} xl={3}>
          <TimezoneContainer form={form} />
        </Col>
      </Row>
    </>
  );
};
