import React, { useEffect, useState } from 'react';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { useLojas } from '../../data/Lojas';
import { CenteredContainer, TableContainer } from './style';
import { Radio } from '@atlaskit/radio';
import { Loja } from '../../protocols/Loja';
import { FieldValues, UseFormReturn } from 'react-hook-form';

type TableProps = {
  form: UseFormReturn<FieldValues, any>;
};

export const Table: React.FC<TableProps> = ({ form }) => {
  const { lojas } = useLojas();

  const [listLojas, setListLojas] = useState<Loja[]>(lojas);

  const { setValue } = form;

  useEffect(() => {
    setListLojas(lojas);
  }, [lojas]);

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'flg_padrao',
      headerName: 'Padrão?',
      width: 85,
      sortable: false,
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <CenteredContainer>
              <Radio
                value={row.cod_loja}
                name="flg_padrao"
                isChecked={row.flg_padrao}
                onChange={handleChangeLojaPadrao}
              />
            </CenteredContainer>
          </>
        );
      },
    },
    {
      field: 'label',
      headerName: 'Loja',
      flex: 1,
      disableColumnMenu: true,
    },
  ];

  const handleChangeLojaPadrao = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = +event.target.value;

    changeDefault(value);
  };

  const changeDefault = (value: number) => {
    const newLojas = listLojas.map((loja) => {
      if (loja.cod_loja === value) {
        loja.flg_padrao = true;
        setValue('cod_loja', loja.cod_loja);
      } else {
        loja.flg_padrao = false;
      }
      return loja;
    });
    setListLojas(newLojas);
  };

  return (
    <TableContainer>
      <DataGrid
        rows={listLojas}
        columns={columns}
        pageSize={5}
        disableSelectionOnClick
        localeText={{
          noRowsLabel: 'Nenhum registro encontrado...',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar columnas',
        }}
      />
    </TableContainer>
  );
};
