import * as yup from 'yup';

export const schemaModalItens = yup.object().shape({
  num_nf: yup
    .number()
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : null)),
  dta_emissao: yup.string(),
  dta_entrada: yup.string(),
});
