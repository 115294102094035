import styled from 'styled-components';

import { TooltipPrimitive } from '@atlaskit/tooltip';

export const TableContainer = styled.div`
  height: 100%;
  height: 400px;
  margin-top: 16px;
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;

  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
`;

export const CutomizedTooltip = styled(TooltipPrimitive)`
  color: #ffff;
  padding: 5px 10px;
  background-color: #57069e;
  border: none;
  border-radius: 0.5rem;
`;
