import {
  moneyFormat,
  transformAsCurrency,
  formatCurrencyAsText,
} from '~/utils/functions';
import { Critica, Produto } from '../protocols';
import { createCritica } from './CreateCritica';
import { UseFormSetValue, FieldValues } from 'react-hook-form';
import { functions } from 'lodash';

type Totais = {
  totalBcIcms: number;
  totalBcSt: number;
  totalVendaVarejo: number;
  totalNf: number;
  totalIcmsOp: number;
  totalIcmsSt: number;
  totalStFcp: number;
  totalIpi: number;
};

interface FormDataFormated {
  val_total_bc: number;
  val_total_icms: number;
  val_total_bc_st: number;
  val_total_icms_st: number;
  val_total_venda_varejo: number;
  val_total_st_fcp: number;
  val_total_nf: number;
  val_total_ipi: number;
}

export function icmsValidation(
  formatedData: FormDataFormated,
  produtos: Produto[],
  tipo_operacao: number,
  setValue: UseFormSetValue<FieldValues>,
): Critica[] {
  const criticas: Critica[] = [];

  const calculaTotaisItens = (): Totais => {
    let totalBcIcms = 0;
    let totalBcSt = 0;
    let totalVendaVarejo = 0;
    let totalNf = 0;
    let totalIcmsOp = 0;
    let totalIcmsSt = 0;
    let totalStFcp = 0;
    let totalIpi = 0;

    produtos.map((item) => {
      totalBcIcms += item.val_bc_icms || 0;
      totalBcSt += item.val_bc_st || 0;
      totalVendaVarejo += item.val_venda_varejo || 0;
      totalNf += item.val_nf || 0;
      totalIcmsOp += item.val_icms || 0;
      totalIcmsSt += item.val_tot_icms_st || 0;
      totalStFcp += item.val_fcp_st || 0;
      totalIpi += item.val_tot_ipi || 0;
      return item;
    });

    return {
      totalBcIcms,
      totalBcSt,
      totalVendaVarejo,
      totalNf,
      totalIcmsOp,
      totalIcmsSt,
      totalStFcp,
      totalIpi,
    };
  };
  function formatCurrencyAsText2(n: number) {
    const FixTwoDecimalPlaces = Number((n || 0).toFixed(2));
    return formatCurrencyAsText(FixTwoDecimalPlaces);
  }

  const totaisItens = calculaTotaisItens();

  const validaTotalBCIcms = () => {
    const valTotBCIcms = transformAsCurrency(formatedData.val_total_bc || 0);
    const { totalBcIcms } = totaisItens;

    if (tipo_operacao === 1) {
      setValue('val_total_bc', formatCurrencyAsText2(totalBcIcms));
      return;
    }

    if (
      transformAsCurrency(valTotBCIcms).toFixed(2) !==
      transformAsCurrency(totalBcIcms).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total BC ICMS OP informado difere da BC ICMS OP Calculada',
        `
        BC Informada: ${moneyFormat(String(valTotBCIcms), 2)} \n
        BC Calculada: ${moneyFormat(String(totalBcIcms), 2)}
        `,
      );
      criticas.push(critica);
    }
  };

  const validaTotalIcmsOp = () => {
    const valTotIcmsOp = transformAsCurrency(formatedData.val_total_icms || 0);
    const { totalIcmsOp } = totaisItens;

    if (tipo_operacao === 1) {
      setValue('val_total_icms', formatCurrencyAsText2(totalIcmsOp));
      return;
    }

    if (
      transformAsCurrency(valTotIcmsOp).toFixed(2) !==
      transformAsCurrency(totalIcmsOp).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total ICMS OP informado difere do ICMS Calculado',
        `
        ICMS Informado: ${moneyFormat(String(valTotIcmsOp), 2)} \n
        ICMS Calculado: ${moneyFormat(String(totalIcmsOp), 2)}
        `,
      );
      criticas.push(critica);
    }
  };

  const validaTotalBcSt = () => {
    const valTotBCST = transformAsCurrency(formatedData.val_total_bc_st || 0);
    const { totalBcSt } = totaisItens;

    if (tipo_operacao === 1) {
      setValue('val_total_bc_st', formatCurrencyAsText2(totalBcSt));
      return;
    }

    if (
      transformAsCurrency(valTotBCST).toFixed(2) !==
      transformAsCurrency(totalBcSt).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total BC ST informado difere da BC ST Calculada',
        `
        BC ST Informada: ${moneyFormat(String(valTotBCST), 2)} \n
        BC ST Calculada: ${moneyFormat(String(totalBcSt), 2)}
        `,
      );
      criticas.push(critica);
    }
  };

  const validaIcmsSt = () => {
    const valTotIcmsSt = transformAsCurrency(
      formatedData.val_total_icms_st || 0,
    );
    const { totalIcmsSt } = totaisItens;

    if (tipo_operacao === 1) {
      setValue('val_total_icms_st', formatCurrencyAsText2(totalIcmsSt));
      return;
    }

    if (
      transformAsCurrency(valTotIcmsSt).toFixed(2) !==
      transformAsCurrency(totalIcmsSt).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total ICMS ST informado difere do Calculado',
        `
        ICMS ST Informado: ${moneyFormat(String(valTotIcmsSt), 2)} \n
        ICMS ST Calculado: ${moneyFormat(String(totalIcmsSt), 2)}
        `,
      );
      criticas.push(critica);
    }
  };

  const validaTotalVendaVarejo = () => {
    const valTotVendaVArejo = transformAsCurrency(
      formatedData.val_total_venda_varejo || 0,
    );
    const { totalVendaVarejo } = totaisItens;

    if (tipo_operacao === 1) {
      setValue(
        'val_total_venda_varejo',
        formatCurrencyAsText2(totalVendaVarejo),
      );
      return;
    }

    if (
      transformAsCurrency(valTotVendaVArejo).toFixed(2) !==
      transformAsCurrency(totalVendaVarejo).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total de Venda Varejo Informado difere do Calculado.',
        `
        Venda Varejo Informado: ${moneyFormat(String(valTotVendaVArejo), 2)} \n
        Venda Varejo Calculado: ${moneyFormat(String(totalVendaVarejo), 2)}
        `,
      );
      criticas.push(critica);
    }
  };

  const validaTotalFcp = () => {
    const valTotStFcp = transformAsCurrency(formatedData.val_total_st_fcp || 0);
    const { totalStFcp } = totaisItens;

    if (tipo_operacao === 1) {
      setValue('val_total_st_fcp', formatCurrencyAsText2(totalStFcp));
      return;
    }

    if (
      transformAsCurrency(valTotStFcp).toFixed(2) !==
      transformAsCurrency(totalStFcp).toFixed(2)
    ) {
      const critica = createCritica(
        4,
        'Total FCP ST Informado difere do Calculado.',
        `
        FCP ST Informado: ${moneyFormat(String(valTotStFcp), 2)} \n
        FCP ST Calculado: ${moneyFormat(String(totalStFcp), 2)}
        `,
      );
      criticas.push(critica);
    }
  };

  // const validaIpi = () => {
  //   const valTotIpi = transformAsCurrency(formatedData.val_total_ipi || 0);
  //   const { totalIpi } = totaisItens;
  //   if (
  //     transformAsCurrency(valTotIpi).toFixed(2) !==
  //     transformAsCurrency(totalIpi).toFixed(2)
  //   ) {
  //     if (tipo_operacao === 1) {
  //       setValue('val_total_ipi', valIpiCalculado);
  //       return;
  //     }
  //     const critica = createCritica(
  //       4,
  //       'Total IPI Informado difere do Calculado.',
  //       `
  //       IPI Informado: ${moneyFormat(String(valTotIpi), 2)} \n
  //       IPI Calculado: ${moneyFormat(String(totalIpi), 2)}
  //       `,
  //     );
  //     criticas.push(critica);
  //   }
  // };

  const validaTotalNf = () => {
    const valTotNf = transformAsCurrency(formatedData.val_total_nf || 0);
    const { totalNf } = totaisItens;

    if (tipo_operacao === 1) {
      setValue('val_total_nf', formatCurrencyAsText2(totalNf));
      return;
    }

    if (
      transformAsCurrency(valTotNf).toFixed(2) !==
      transformAsCurrency(totalNf).toFixed(2)
    ) {
      const critica = createCritica(
        0,
        'Total da NF informado difere do Calculado.',
        `
        Valor Total Informado: ${moneyFormat(String(valTotNf), 2)} \n
        Valor Total Calculado: ${moneyFormat(String(totalNf), 2)}
        `,
      );
      criticas.push(critica);
    }
  };

  validaTotalBCIcms();
  validaTotalIcmsOp();
  validaTotalBcSt();
  validaIcmsSt();
  validaTotalVendaVarejo();
  validaTotalFcp();
  // validaIpi();
  validaTotalNf();

  return criticas;
}
