import { Select } from '../protocols';

export const timezones: Select[] = [
  {
    label: 'Brazil/Acre',
    value: 'Brazil/Acre',
  },
  {
    label: 'America/Sao_Paulo',
    value: 'America/Sao_Paulo',
  },
  {
    label: 'Brazil/DeNoronha',
    value: 'Brazil/DeNoronha',
  },
];
