import { useEffect, useState } from 'react';
import api from '~/services/api';
import { Loja } from '../protocols/Loja';

export const useLojas = () => {
  const [lojas, setLojas] = useState<Loja[]>([]);

  async function getLojas() {
    const res = await api.get('/lojas/usuario');
    const { data, success, message } = res.data;
    if (!success) {
      throw new Error(message);
    }
    const regs = data.map((item: Loja) => {
      return {
        id: item.cod_loja,
        cod_loja: item.cod_loja,
        flg_padrao: item.flg_padrao,
        des_loja: item.des_loja,
        num_cnpj: item.num_cnpj,
        label: `${item.cod_loja} - ${item.des_loja} (${item.num_cnpj})`,
      };
    });
    setLojas(regs);
  }

  useEffect(() => {
    getLojas();
  }, []);

  return { lojas };
};
