import { formatCurrencyAsText, moneyFormat } from '~/utils/functions';
import { Critica, Produto } from '../protocols';
import { createCritica } from './CreateCritica';
import { transformAsCurrency } from '~/utils/functions';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

type TotalItens = {
  totalIpiItens: number;
};

interface FormDataFormated {
  val_total_ipi: number;
}

function formatCurrencyAsText2(n: number) {
  const FixTwoDecimalPlaces = Number((n || 0).toFixed(2));
  return formatCurrencyAsText(FixTwoDecimalPlaces);
}

// export function nfItensValidation(formatedData: formatedData): Critica[] {
export function nfItensValidation(
  formatedData: FormDataFormated,
  produtos: Produto[],
  tipo_operacao: number,
  setValue: UseFormSetValue<FieldValues>,
): Critica[] {
  const criticas: Critica[] = [];

  const calculaTotaisItens = (): TotalItens => {
    let totalIpiItens = 0;

    produtos.map((item) => {
      totalIpiItens += item.val_tot_ipi || 0;
      return item;
    });

    return {
      totalIpiItens,
    };
  };

  const totais = calculaTotaisItens();

  const validaTotalIPI = () => {
    const valTotIpi = transformAsCurrency(formatedData.val_total_ipi) || 0;
    const valIpiCalculado = totais.totalIpiItens;

    if (tipo_operacao === 1) {
      setValue('val_total_ipi', formatCurrencyAsText2(valIpiCalculado));
      return;
    }

    if (valTotIpi !== valIpiCalculado) {
      const critica = createCritica(
        1,
        'Total Informado de IPI difere do Total Calculado',
        `Total do IPI informado: ${formatCurrencyAsText(
          valTotIpi,
        )} \n Total do IPI calculado: ${moneyFormat(
          String(valIpiCalculado),
          2,
        )}`,
      );
      criticas.push(critica);
    }
  };

  const validaValoresItens = () => {
    produtos.map((item) => {
      const qtdEntrada = item.qtd_entrada_ant;
      const valTabela = item.val_tabela || 0;
      const qtdEmbalagem = item.qtd_embalagem;
      const valCustoRep = item.val_custo_rep;

      if (qtdEntrada < 0 || valTabela <= 0 || qtdEmbalagem <= 0) {
        const critica = createCritica(
          1,
          'Produto com Quantidade Inválida',
          `
        ${item.cod_produto} - ${item.des_produto} \n
        Qtd. Entrada: ${formatCurrencyAsText(qtdEntrada)} \n
        Valor Tabela: ${formatCurrencyAsText(valTabela)} \n
        Qtd. Embalagem: ${formatCurrencyAsText(qtdEmbalagem)}
        `,
        );
        criticas.push(critica);
      }

      if (valCustoRep <= 0) {
        const critica = createCritica(
          1,
          'Custo Reposição Negativo',
          `
        ${item.cod_produto} - ${item.des_produto} \n
        Custo Rep. Negativo: ${formatCurrencyAsText(valCustoRep)} \n
        `,
        );
        criticas.push(critica);
      }
      return item;
    });
  };

  validaTotalIPI();
  validaValoresItens();

  return criticas;
}
